.App{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.InfoIcon {
  cursor: pointer;
}

.AboutMainWrapper {
  width: 100%;
  height: 370px;
  background-color: rgb(245, 245, 245);
}

.AboutMain {
  width: 800px;
  height: 400px;
  margin: auto;
  align-items: center;
  display: flex;
  padding-top: 15px;
}

.About__leftSide {
  color: rgb(32, 32, 32);
  font-weight: normal;
  font-family: Helvetica, sans-serif;
  /* background-color: blue; */
  /* align-items: center; */
  flex: 50%;
  /* display: flex; */
  flex-direction: row;
  justify-content: left;
  /* margin-left: 12%; */
  /* padding-top: 150px; */
}

.About__rightSide {
  /* background-color: red; */
  /* align-items: center; */
  /* margin-left: 8%; */
  flex: 50%;
  /* display: flex; */
  justify-content: center;
  text-align: center;
}

.pageTitle {
  color: rgb(32, 32, 32);
  /* color: rgb(96, 108, 123); */
  font-weight: normal;
  letter-spacing: 3px;
  font-family: Helvetica, sans-serif;
  font-size: 28px;
  display: block;
  text-align: center;
  padding-top: 270px;
}
.pageTitle2 {
  color: rgb(32, 32, 32);
  text-align: center;
  font-weight: normal;
  letter-spacing: 3px;
  font-family: Helvetica, sans-serif;
  font-size: 20px;

}
.title {
  color: rgb(32, 32, 32);
  font-weight: normal;
  /* letter-spacing: 3px; */
  font-family: Helvetica, sans-serif;

}

.backgroudEffect{
    /* background-position: center; */
    width: 100%;
    height: 600px;
    /* height: 100vh; */
    /* background-size: cover; */
    /* background-image: linear-gradient(rgba(255, 255, 255, 0.952), rgba(255, 255, 255, 0.996), rgba(95, 93, 93, 0.925), rgba(204, 143, 143, 0.911) ); */
    background: linear-gradient(-20deg, rgba(255, 255, 255, 0.502), rgb(255, 255, 255), rgba(255, 255, 255, 0.536), rgb(255, 255, 255) );
    background-size: 300% 300%;
    animation: change 10s ease-in-out infinite;
}

@keyframes change {
  from{
    background-position: 0 50%;
  }
  50%{
    background-position: 100% 50%;
  }
  to{
    background-position: 0 50%;
  }
}

.skillsContainer {
    /* background-image: linear-gradient(rgb(250, 250, 250), rgb(231, 231, 231)); */
    background-color: rgb(255, 255, 255);
    background-position: center;
    width: 100%;
    height: auto;
    background-size: cover;
    text-align: center;
}

.footer {
  margin-top: 10px;
  background-color: rgb(247, 247, 247);
  background-position: center;
  width: 100%;
  height: 300px;
  background-size: cover;
  text-align: center;
}

.foto {
  max-width: 250px;
  width: 100%;
  border-radius: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

.aboutContainer0101 {
  width: 100%;
  max-width: 2000px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  /* max-width: 1000px; */
  font-size: 12px;
  color: rgb(220, 220, 220);
  line-height: 0.9;
  height: 600px;
  z-index: -1;
  word-wrap: break-word;
  overflow: hidden;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
}

.aboutContainer {
  z-index: 1;
  width: 100%;
  height: 600px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
}

.aboutFont{
  font-size: 1.1em;
  /* background-color: rgba(255, 255, 255, 0.555); */
}

.defaultFont{
  font-size: 1.1em;
  line-height: 1.4;

}

.logo {
  width: 40px;
  margin-inline: 40px;
}

.programmingSkills {
  width: 95%;
  margin-top: 60px;
  margin-bottom: 150px;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  text-align: center;
}

.projectContainerParent {
  /* background-color: brown; */
  /* display: inline-block; */
  width: 100%;
  margin: auto;
  max-width: 1100px;
  height: 100%;
  text-align: center;
  /* text-align: center; */
}

.iconContainer {
  display: inline-block;

}

.projectContainer__R{
  /* display: inline-block; */
  width: 600px;
  margin: 10px ;
  display: inline-block;
  background-color: rgb(241, 241, 241);
  border-radius: 5px;
}

.projectContainer__Exp{
  width: 90%;
  margin: 10px ;
  display: inline-block;
  background-color: rgb(241, 241, 241);
  border-radius: 5px;
}

/* .projectContainer__L{
  width: 40%;
  height: auto;
  display: inline-block;
  background-color: blue;
} */



/* .projectContainer__inner{
  width: 90%;
  height: 90%;
  background-color: cadetblue;
} */

.contactInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
}
.contactIcons {
  margin: 0px 5px;
}

.copyright {
  position: relative;
  /* border-top: 1px solid grey; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
}

.AboutInfoContainer {
  height: 100%;
  margin-top: 30px;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 800px;
  /* display: inline-block; */
  /* background-color: brown; */
}

.pInfo {
  font-size: 1.2em;
  font-family: Helvetica, sans-serif;
  line-height: 1.4;
  /* margin-left: 10px;
  margin-right: 10px; */
  /* -webkit-column-count: 2;
     -moz-column-count: 2;
          column-count: 2; */

  /* margin-left: 30px; */
}

.AboutInfoContainer__rightSide {
  width: 50%;
  margin-left: 30px;
}

.AboutInfoContainer__leftSide {
  width: 50%;
  margin-right: 30px;
}

.linkBtn {
  border: solid 1px rgb(0, 0, 0);
  color: black;
  font-size: 12px;
  border-radius: 12px;
  padding: 4px 12px;
  background-color: rgba(122, 191, 255, 0);
  cursor: pointer;
}

.linkBtn:hover {
  background-color: rgba(211, 211, 211, 0.438);
}

/* .projectImg {
  width: 400px;
} */
/* .hidden{
  display: none;
} */


/* 
.carousel .c__container {
  

  display: flex; 
  flex-direction: row;
} */


@media only screen and (max-width: 1000px) {
  .AboutInfoContainer {
    max-width: 700px;
  }
  .AboutMain {
    width: 700px;
  }
  .pInfo {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 900px) {
  .AboutInfoContainer {
    max-width: 600px;
  }
  .AboutMain {
    width: 600px;
  }
}

@media screen and (max-width: 700px) {
 
  .AboutMainWrapper {
    width: 100%;
    height: 420px;
    padding-top: 70px;
  }

  .AboutMain {
    height: 400px;
    width: 100%;
    margin: auto;
    align-items: center;
    display: flex;
    text-align: center;
    flex-direction: column;
    padding-top: 15px;
  }

  .AboutInfoContainer {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    /* display: inline-block; */
  }
  
  .AboutInfoContainer__rightSide {
    width: 100%;
    margin-left: 0px;
  }
  
  .AboutInfoContainer__leftSide {
    width: 100%;
    margin-right: 0px;
  }
}
