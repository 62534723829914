.Reduced {
    max-width: 600px;
    width: auto;
    margin: 10px ;
    display: inline-block;
    background-color: rgb(241, 241, 241);
    border-radius: 5px;
    transition: 100ms;
}

.Expanded {
    width: 90%;
    max-width: 2000px;
    margin: 10px ;
    display: inline-block;
    background-color: rgb(241, 241, 241);
    border-radius: 5px;
    transition: 100ms;
}

.pContainer {
    width: 95%;
    margin: auto;
    text-align: left;
}

.InfoIcon {
    left: 90%;
}

.infIconContainer {
    width: 100%;
    text-align: end;
}

.hidden {
    display: none;
}

.projectImg {
    width: 100%;
    max-width: 400px;
}

.titleFont {
    font-family: Helvetica, Arial, sans-serif;
    margin-bottom: 4px;
}

.titleHeader {
    font-family: Helvetica, Arial, sans-serif;
}

.linkContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.link {
    margin: 0px 5px;
}


  