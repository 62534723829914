
.Navbar, .NavBar__white {
    background-color: rgba(255, 255, 255, 0);
    width: 100%;
    height: 50px;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    position: fixed;
    transition-timing-function: ease-in;
    transition: 0.8s;
}
.NavBar__white {
    background-color: rgba(255, 255, 255);
    /* border-bottom: 1px solid rgb(250, 250, 250); */
}

.leftSide {
    align-items: center;
    flex: 50%;
    display: flex;
    justify-content: left;
}

.leftSide .links a {
    max-height: 80px;
    flex: 50%;
    color: rgb(60, 60, 60);
    font-size: 1.2em;
    text-decoration: none;
    margin-left: 40px
}

.rightSide .links a {
    max-height: 80px;
    flex: 50%;
    color: rgb(60, 60, 60);
    text-decoration: none;
    font-size: 1.2em;
    margin-left: 40px
}

.rightSide {
    display: flex;
    align-items: center;
    flex: 50%;
    justify-content: flex-end;
    margin-right: 40px;
}

.Navbar .rightSide #hidden {
    display: flex;
}
.links a:hover {
    text-decoration: underline;

}


/*------- deprecated-------*/
/* .expanding_btn {
    display: none;
} */
  
@media only screen and (max-width: 700px) {

    /*------- deprecated-------*/
    /* .expanding_btn {
      display: flex; 
    } */

    .leftSide .links a {
        font-size: 1.1em;
        margin-left: 30px;
        flex: 80%;
    }

    .leftSide {
        flex: 80%;
    }
    .rightSide {
        flex: 20%;
    }

    .rightSide .links {
      display: none;
   
    }
  
    .Navbar .rightSide #hidden {
      position: absolute;
      left: 0px;
      top: 50px;
      height: 230px;
      background-color: rgba(255, 255, 255, 0.747);
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
}