.container {
    display: inline-block;
    cursor: pointer;
    display: none;
  }
  
  .bar1, .bar2, .bar3 {
    width: 30px;
    height: 3px;
    background-color: rgb(60, 60, 60);;
    margin: 6px 0;
    transition: 0.4s;
  }
  
  #change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-6x, 6px);
    transform: rotate(-45deg) translate(-5px, 4px);
  }
  
  #change .bar2 {opacity: 0;}
  
  #change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }

  /* .expanding_btn {
    display: none;
} */
  
@media only screen and (max-width: 700px) {
    .container {
      display: block;
    }
}