/* .InfoStyle {
    display: inline;
} */

.resumeContainer {
    display: block;
    /* flex-direction: column; */
    width: 80%;
    max-width: 1000px;
    margin: auto;
    padding-top: 100px;
    padding-bottom: 50px;
}
.TitleCategory{
    font-family: Helvetica, Arial, sans-serif;
}
.sectionContainer {
    margin-left: 300px;
}

.InfoStyleTitle, .InfoStyleDegree, .InfoStyleDate {
    font-family: Helvetica, Arial, sans-serif;
    text-align: left;
}

.InfoStyleTitle {
    font-weight: bold;
}
.InfoStyleDate {
    font-size: small;
    color: gray;
    font-style: italic;
}

/* .InfoStyle {
    margin-left: 300px;
    
} */
h4 {
    display: inline;
}
#ResumeProjectaPart{
    /* margin-left: 300px; */
    background-color: yellow;
    width: fit-content;

    
}


@media only screen and (max-width: 800px) {
    .sectionContainer {
        margin-left: 40px;
    }

}